import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import authServices from "./authServices";
import toast from "react-hot-toast";

function LoginInvoice() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      await authServices.login(email, password);
      toast.success("Login successful");
      navigate("/invoice");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || error.message || "An error occurred";
      setError(errorMessage);
    }

    setLoading(false);
  };

  return (
    <div className="flex items-center justify-center h-screen ">
      <div className="p-8 bg-white shadow-md rounded-lg md:w-[25%] w-[95%]">
        <div className="flex justify-center flex-col items-center">
          <img
            src="/logo.webp"
            alt="Logo"
            className="w-[250px] object-contain"
          />
          <h2 className="text-2xl font-semibold mb-4 text-center">
            Login to App Invoice
          </h2>
        </div>
        <form onSubmit={handleLogin} className="space-y-4 text-black">
          <div>
            <label htmlFor="email" className="block text-sm font-medium">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium">
              Password
            </label>
            <input
              type="password"
              id="password"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white 
              bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-300 ease-in-out
            "
            disabled={loading}
          >
            {loading ? "Loading..." : "Login"}
          </button>
          {error && <div className="text-red-600 text-sm">{error}</div>}
        </form>
      </div>
    </div>
  );
}

export default LoginInvoice;
