import { InvoicesContent } from "../components/Invoice/InvoicesContent";

function Invoice() {
  return (
    <div className="min-h-screen">
      <InvoicesContent />
    </div>
  );
}

export default Invoice;
