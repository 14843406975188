import { useParams } from "react-router";
import FormInvoice from "./FormInvoice";
import { IoArrowBack } from "react-icons/io5";
import { LogoutButton } from "./Logout";
import { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { Link } from "react-router-dom";

const FormInvoiceUpdate = () => {
  const id = useParams().id;
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <div>
      <div className="bg-gray-800 shadow-md p-4 text-white">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <div>
            <Link
              to="/invoice"
              className="flex items-center text-gray-300 hover:text-blue-500 gap-2"
            >
              <IoArrowBack />
              <span>Regresar</span>
            </Link>
          </div>

          <h1 className="text-2xl font-semibold">{rpdata.dbPrincipal.name}</h1>

          <div className="flex items-center gap-4">
            <LogoutButton />
          </div>
        </div>
      </div>
      <div className="flex w-[100%] relative md:w-[90%] mx-auto flex-wrap">
        <FormInvoice isUpdate={true} id={id} />
      </div>
    </div>
  );
};

export default FormInvoiceUpdate;
