import { useContext, useState } from "react";
import ClientInvoices from "./InvoiceByClient";
import { LogoutButton } from "./Logout";
import { GlobalDataContext } from "../../context/context";
import FormInvoice from "./FormInvoice";
import { Link } from "react-router-dom";
import { IoArrowBack, IoAdd } from "react-icons/io5";

export const InvoicesContent = () => {
  const [showInvoice, setShowInvoice] = useState(false);

  const { rpdata } = useContext(GlobalDataContext);

  return (
    <div>
      <div className="md:bg-gray-800 shadow-md p-4 text-white">
        <div className="md:max-w-7xl mx-auto flex md:justify-between items-center flex-wrap justify-center text-center gap-4">
          <div>
            <Link
              to="/invoice"
              className="flex items-center text-gray-300 hover:text-blue-500 gap-2"
            >
              <IoArrowBack />
              <span>Regresar</span>
            </Link>
          </div>

          <h1 className="text-2xl font-semibold">{rpdata.dbPrincipal.name}</h1>

          <div className="flex items-center gap-4">
            <button
              onClick={() => setShowInvoice(true)}
              className="bg-blue-500 px-4 py-2 rounded-md flex items-center gap-2 hover:bg-blue-600 transition duration-300 ease-in-out"
            >
              <IoAdd className="text-lg" />
              <span>New Invoice</span>
            </button>
            <LogoutButton />
          </div>
        </div>
      </div>
      <div className="py-8">
        {showInvoice ? (
          <FormInvoice isUpdate={false} setShowInvoice={setShowInvoice} />
        ) : (
          <ClientInvoices clienteId={rpdata.docId} />
        )}
      </div>
    </div>
  );
};
